import React from "react";
import { Container, Grid, Typography, Box, TextField, Button, Card, Paper, Divider } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import SendIcon from '@mui/icons-material/Send';

class Contact extends React.Component {
  render() {
    return (
      <section id="contact" className="sect-pt4 route">
        <Container maxWidth="xl">
          {/* Section Header */}
          <Box className="section-header" sx={{ mb: 6 }}>
            <Typography variant="h2" component="h2">
              Contact Us
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, maxWidth: '700px', mx: 'auto', color: '#666' }}>
              Get in touch with our team to discuss your construction and engineering needs
            </Typography>
          </Box>

          <Grid container spacing={5}>
            {/* Contact Information */}
            <Grid item xs={12} md={5}>
              <Card sx={{ 
                p: 4, 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
              }}>
                <Typography variant="h4" component="h3" sx={{ mb: 4, fontWeight: 600 }}>
                  Get In Touch
                </Typography>
                
                <Box sx={{ mb: 4 }}>
                  <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.8 }}>
                    We're here to answer any questions you may have about our construction and engineering services. Reach out to us and we'll respond as soon as we can.
                  </Typography>
                </Box>
                
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
                    <LocationOnIcon sx={{ color: 'var(--primary-color)', mr: 2, mt: 0.5 }} />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                        Address
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        21 WOODLANDS CLOSE #08-44<br />
                        PRIMZ BIZHUB Singapore 737854
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
                    <PhoneIcon sx={{ color: 'var(--primary-color)', mr: 2, mt: 0.5 }} />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                        Phone
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        +65 6271 8115
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 4 }}>
                    <EmailIcon sx={{ color: 'var(--primary-color)', mr: 2, mt: 0.5 }} />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                        Email
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <a 
                          href="mailto:enquiry@chinhoong.com.sg" 
                          style={{ 
                            color: 'var(--primary-color)', 
                            textDecoration: 'none' 
                          }}
                        >
                          enquiry@chinhoong.com.sg
                        </a>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                
                <Divider sx={{ my: 3 }} />
                
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                    Follow Us
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button 
                      variant="outlined" 
                      sx={{ 
                        minWidth: 0, 
                        width: 40, 
                        height: 40, 
                        borderRadius: '50%',
                        color: 'var(--primary-color)',
                        borderColor: 'rgba(0,123,255,0.3)',
                        '&:hover': {
                          borderColor: 'var(--primary-color)',
                          backgroundColor: 'rgba(0,123,255,0.05)'
                        }
                      }}
                    >
                      <FacebookIcon fontSize="small" />
                    </Button>
                    <Button 
                      variant="outlined" 
                      sx={{ 
                        minWidth: 0, 
                        width: 40, 
                        height: 40, 
                        borderRadius: '50%',
                        color: 'var(--primary-color)',
                        borderColor: 'rgba(0,123,255,0.3)',
                        '&:hover': {
                          borderColor: 'var(--primary-color)',
                          backgroundColor: 'rgba(0,123,255,0.05)'
                        }
                      }}
                    >
                      <LinkedInIcon fontSize="small" />
                    </Button>
                    <Button 
                      variant="outlined" 
                      sx={{ 
                        minWidth: 0, 
                        width: 40, 
                        height: 40, 
                        borderRadius: '50%',
                        color: 'var(--primary-color)',
                        borderColor: 'rgba(0,123,255,0.3)',
                        '&:hover': {
                          borderColor: 'var(--primary-color)',
                          backgroundColor: 'rgba(0,123,255,0.05)'
                        }
                      }}
                    >
                      <InstagramIcon fontSize="small" />
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
            
            {/* Contact Form */}
            <Grid item xs={12} md={7}>
              <Card sx={{ 
                p: 4, 
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
              }}>
                <Typography variant="h4" component="h3" sx={{ mb: 4, fontWeight: 600 }}>
                  Send Us A Message
                </Typography>
                
                <form action="https://formspree.io/xdoeonlo" method="POST">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Your Name"
                        name="name"
                        variant="outlined"
                        required
                        sx={{ 
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: 'var(--primary-color)',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Your Email"
                        name="email"
                        type="email"
                        variant="outlined"
                        required
                        sx={{ 
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: 'var(--primary-color)',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Subject"
                        name="subject"
                        variant="outlined"
                        required
                        sx={{ 
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: 'var(--primary-color)',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        name="message"
                        variant="outlined"
                        multiline
                        rows={6}
                        required
                        sx={{ 
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: 'var(--primary-color)',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        endIcon={<SendIcon />}
                        sx={{ 
                          bgcolor: 'var(--primary-color)', 
                          px: 4,
                          py: 1.5,
                          fontSize: '1rem',
                          fontWeight: 500,
                          '&:hover': {
                            bgcolor: '#0056b3',
                          }
                        }}
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Card>
            </Grid>
          </Grid>
          
          {/* Map Section */}
          <Box sx={{ mt: 8, mb: 4 }}>
            <Paper 
              elevation={3} 
              sx={{ 
                height: 400, 
                width: '100%',
                overflow: 'hidden',
                borderRadius: '8px'
              }}
            >
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6170133184407!2d103.78651841475407!3d1.4290139989543738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da11a211fe618b%3A0x9be0e3aad479dd30!2s21%20Woodlands%20Close%2C%20Primz%20BizHub%2C%20Singapore%20737854!5e0!3m2!1sen!2ssg!4v1615440000000!5m2!1sen!2ssg" 
                width="100%" 
                height="100%" 
                style={{ border: 0 }} 
                allowFullScreen="" 
                loading="lazy"
                title="Office Location"
              ></iframe>
            </Paper>
          </Box>
          
          {/* Footer */}
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="body2" color="text.secondary">
              © {new Date().getFullYear()} Chin Hoong Construction & Engineering Pte Ltd. All Rights Reserved.
            </Typography>
          </Box>
        </Container>
      </section>
    );
  }
}

export default Contact;
