import React from "react";
import $ from "jquery";
import { AppBar, Toolbar, Container, Button, IconButton, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import logo1 from "../img/company.png";
import logo2 from "../img/company.png";

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      logo: logo1,
      mobileMenuOpen: false,
      isScrolled: false,
      scrollProgress: 0
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    $(".js-scroll").on("click", () => {
      this.setState({ mobileMenuOpen: false });
    });

    $('a.js-scroll[href*="#"]:not([href="#"])').on("click", function(e) {
      if (
        window.location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        window.location.hostname === this.hostname
      ) {
        e.preventDefault();
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          $("html, body").animate(
            {
              scrollTop: target.offset().top - 70
            },
            500,
            "easeInOutQuad"
          );
          return false;
        }
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollY = window.scrollY;
    const scrollThreshold = 100;
    const progress = Math.min(scrollY / scrollThreshold, 1);
    
    this.setState({ 
      isScrolled: scrollY > 50,
      scrollProgress: progress
    });
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      mobileMenuOpen: !prevState.mobileMenuOpen
    }));
  };

  render() {
    const { mobileMenuOpen, isScrolled, scrollProgress } = this.state;
    
    const logoStyle = {
      height: 110,
      width: 'auto',
      marginRight: '1rem'
    };
    
    const bgOpacity = scrollProgress * 0.95;
    const textColorValue = Math.round(51 + (255 - 51) * (1 - scrollProgress));
    const textColor = `rgb(${textColorValue}, ${textColorValue}, ${textColorValue})`;
    const shadowOpacity = scrollProgress * 0.1;
    
    const navbarStyle = {
      backgroundColor: `rgba(255, 255, 255, ${bgOpacity})`,
      boxShadow: isScrolled ? `0 2px 10px rgba(0, 0, 0, ${shadowOpacity})` : 'none',
      transition: 'all 0.4s ease',
      backdropFilter: `blur(${scrollProgress * 5}px)`,
      paddingTop: '10px'
    };
    
    const linkStyle = {
      color: textColor,
      textDecoration: 'none',
      fontWeight: 500,
      fontSize: '1.2rem',
      letterSpacing: '0.5px',
      padding: '8px 20px',
      transition: 'color 0.4s ease',
      '&:hover': {
        color: '#007bff'
      }
    };

    const titleStyle = {
      typography: 'h6',
      color: textColor,
      fontSize: { xs: '1.3rem', md: '1.6rem' },
      fontWeight: 500,
      lineHeight: 1.2,
      transition: 'color 0.4s ease',
      marginLeft: '1rem'
    };

    const menuItems = [
      { text: 'Home', href: '#home' },
      { text: 'About', href: '#about' },
      { text: 'Projects', href: '#work' },
      { text: 'Services', href: '#services' },
      { text: 'Contact', href: '#contact' }
    ];

    return (
      <>
        <AppBar position="fixed" sx={navbarStyle} elevation={0}>
          <Container maxWidth="xl">
            <Toolbar disableGutters sx={{ 
              minHeight: '65px',
              paddingTop: '4px',
              paddingBottom: '4px',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <a href="#page-top" className="js-scroll">
                  <img
                    src={this.state.logo}
                    alt="logo"
                    style={logoStyle}
                  />
                </a>
                <Box sx={titleStyle}>
                  Chin Hoong Construction & Engineering Pte Ltd
                </Box>
              </Box>

              {/* Desktop Menu */}
              <Box sx={{ 
                display: { xs: 'none', md: 'flex' }, 
                alignItems: 'center',
                marginLeft: 'auto'
              }}>
                {menuItems.map((item) => (
                  <Button
                    key={item.text}
                    href={item.href}
                    className="js-scroll"
                    sx={{
                      ...linkStyle,
                      textTransform: 'none',
                      mx: 0.5,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#007bff'
                      }
                    }}
                  >
                    {item.text}
                  </Button>
                ))}
              </Box>

              {/* Mobile Menu Icon */}
              <IconButton
                sx={{ 
                  display: { xs: 'flex', md: 'none' }, 
                  color: textColor,
                  transition: 'color 0.4s ease'
                }}
                onClick={this.toggleMobileMenu}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>

        {/* Mobile Menu Drawer */}
        <Drawer
          anchor="right"
          open={mobileMenuOpen}
          onClose={this.toggleMobileMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              width: 240,
              backgroundColor: '#fff'
            }
          }}
        >
          <List sx={{ pt: 8 }}>
            {menuItems.map((item) => (
              <ListItem 
                key={item.text}
                onClick={this.toggleMobileMenu}
                sx={{ padding: 0 }}
              >
                <Button
                  href={item.href}
                  className="js-scroll"
                  sx={{
                    width: '100%',
                    py: 2,
                    color: '#333',
                    justifyContent: 'flex-start',
                    pl: 3,
                    fontSize: '1.2rem',
                    fontWeight: 500
                  }}
                >
                  {item.text}
                </Button>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </>
    );
  }
}

export default Navbar;
