import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Card, CardContent, Typography, Container, Grid, Box, Button, Divider, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';

//import stock
import stock from "../img/image1.jpg";
import stock1 from "../img/image2.jpg";
import stock2 from "../img/image3.jpg";
import stock3 from "../img/image4.jpg";
import stock4 from "../img/image5.jpg";
import stock5 from "../img/image6.jpg";
import panel1 from "../img/services/panel/panel.JPG";
import panel2 from "../img/services/panel/panel2.jpg";
import panel3 from "../img/services/panel/panel3.jpeg";
import panel4 from "../img/services/panel/Panel4.jpeg";
import panel5 from "../img/services/panel/Panel5.jpeg";
import tile1 from "../img/services/Tiling/Tile.jpg";
import tile2 from "../img/services/Tiling/Tile2.jpg";
import tile3 from "../img/services/Tiling/Tile3.jpeg";
import tile4 from "../img/services/Tiling/Tile4.jpeg";
import tile5 from "../img/services/Tiling/Tile5.jpeg";
import pebble1 from "../img/services/PebbleWash/Pebble.jpeg";
import pebble2 from "../img/services/PebbleWash/Pebble2.jpeg";
import pebble3 from "../img/services/PebbleWash/Pebble3.jpeg";
import pebble4 from "../img/services/PebbleWash/Pebble4.jpeg";
import skim1 from "../img/services/Skimcoat/Skimcoat.jpeg";
import skim2 from "../img/services/Skimcoat/Skimcoat2.jpeg";
import skim3 from "../img/services/Skimcoat/Skimcoat3.jpeg";
import plaster1 from "../img/services/Plastering/Plaster.jpeg";
import plaster2 from "../img/services/Plastering/Plaster2.jpeg";
import skirting1 from "../img/services/Skirting/Skirting.jpeg";

class Services extends React.Component {
  constructor() {
    super();
    this.state = {
      services: [
        {
          id: "panel",
          title: "Panel Installation",
          description: "Professional installation of precast wall panels for residential and commercial projects. Our team ensures precise fitting and secure mounting for long-lasting results.",
          images: [panel1, panel2, panel3, panel4, panel5]
        },
        {
          id: "tiling",
          title: "Tiling",
          description: "Expert tiling services with precision and attention to detail. We work with various tile materials including ceramic, porcelain, natural stone, and mosaic for floors and walls.",
          images: [tile1, tile2, tile3, tile4, tile5]
        },
        {
          id: "pebble",
          title: "Pebble Wash",
          description: "High-quality pebble wash finishes that add texture and visual interest to exterior surfaces. Durable and weather-resistant for Singapore's climate.",
          images: [pebble1, pebble2, pebble3, pebble4]
        },
        {
          id: "skim",
          title: "Skimcoat",
          description: "Professional skimcoating services to create smooth, even surfaces ready for painting or other finishes. Perfect for repairing damaged walls or preparing new surfaces.",
          images: [skim1, skim2, skim3]
        },
        {
          id: "plaster",
          title: "Plastering",
          description: "Expert plastering services for interior and exterior walls. Our skilled team delivers smooth, durable finishes that stand the test of time.",
          images: [plaster1, plaster2]
        },
        {
          id: "skirting",
          title: "Skirting",
          description: "Professional installation of skirting boards to provide a finished look between walls and floors. Available in various materials and styles to match your interior design.",
          images: [skirting1]
        }
      ],
      dialogOpen: false
    };
  }

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const slideProperties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true
    };

    return (
      <section id="services" className="sect-pt4 route" style={{ backgroundColor: '#f8f9fa' }}>
        <Container maxWidth="xl">
          {/* Section Header */}
          <Box className="section-header" sx={{ mb: 6 }}>
            <Typography variant="h2" component="h2">
              Our Services
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, maxWidth: '700px', mx: 'auto', color: '#666' }}>
              We provide a comprehensive range of construction and engineering services with a focus on quality and precision
            </Typography>
          </Box>

          {/* Services Introduction */}
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography variant="body1" sx={{ maxWidth: '900px', mx: 'auto', mb: 4, lineHeight: 1.8 }}>
              At Chin Hoong Construction & Engineering, we specialize in a wide range of construction services tailored to meet the unique requirements of each project. Our team of skilled professionals is committed to delivering high-quality workmanship and exceptional results.
            </Typography>
            <Divider sx={{ width: '100px', mx: 'auto', mb: 4, borderColor: 'var(--primary-color)', borderWidth: 2 }} />
          </Box>

          {/* Services Grid */}
          <Grid container spacing={4}>
            {this.state.services.map((service, index) => (
              <Grid item xs={12} md={6} key={service.id}>
                <Card className="modern-card" sx={{ 
                  height: '100%', 
                  overflow: 'hidden',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)'
                  }
                }}>
                  <Box sx={{ position: 'relative' }}>
                    <Slide {...slideProperties}>
                      {service.images.map((image, imgIndex) => (
                        <div className="each-slide" key={imgIndex}>
                          <img 
                            src={image} 
                            alt={`${service.title} ${imgIndex + 1}`} 
                            style={{ 
                              width: '100%', 
                              height: 350, 
                              objectFit: 'cover' 
                            }} 
                          />
                        </div>
                      ))}
                    </Slide>
                  </Box>
                  <CardContent sx={{ p: 4 }}>
                    <Typography 
                      variant="h5" 
                      component="h3" 
                      sx={{ 
                        fontWeight: 600, 
                        mb: 2,
                        color: '#333'
                      }}
                    >
                      {service.title}
                    </Typography>
                    <Divider sx={{ mb: 3 }} />
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 3,
                        color: '#666',
                        lineHeight: 1.7
                      }}
                    >
                      {service.description}
                    </Typography>
                    {/* <Button 
                      variant="text" 
                      endIcon={<ArrowForwardIcon />}
                      onClick={this.handleOpenDialog}
                      sx={{ 
                        color: 'var(--primary-color)',
                        p: 0,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      Learn More
                    </Button> */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Popup Dialog */}
          <Dialog
            open={this.state.dialogOpen}
            onClose={this.handleCloseDialog}
            maxWidth="sm"
            fullWidth
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: '12px',
                padding: '16px'
              }
            }}
          >
            <DialogTitle sx={{ 
              textAlign: 'center',
              fontSize: '2rem',
              fontWeight: 600,
              color: '#333',
              pt: 3
            }}>
              Hi Jaslyn, How Are You?
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="h6" sx={{ color: '#666' }}>
                😊 We hope you're having a great day! 😊
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
              <Button 
                onClick={this.handleCloseDialog}
                variant="contained"
                startIcon={<CloseIcon />}
                sx={{ 
                  bgcolor: 'var(--primary-color)',
                  color: '#fff',
                  '&:hover': {
                    bgcolor: '#0056b3'
                  }
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Call to Action */}
          <Box 
            sx={{ 
              mt: 8, 
              p: 5, 
              textAlign: 'center',
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
            }}
          >
            <Typography variant="h4" component="h3" sx={{ mb: 3, fontWeight: 600 }}>
              Need a Customized Solution?
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, maxWidth: '700px', mx: 'auto' }}>
              Our team is ready to discuss your specific requirements and provide tailored construction and engineering solutions for your project.
            </Typography>
            <Button 
              variant="contained" 
              href="#contact" 
              className="js-scroll"
              sx={{ 
                bgcolor: 'var(--primary-color)', 
                px: 4,
                py: 1.5,
                fontSize: '1rem',
                fontWeight: 500,
                '&:hover': {
                  bgcolor: '#0056b3',
                }
              }}
            >
              Contact Us Today
            </Button>
          </Box>
        </Container>
      </section>
    );
  }
}

export default Services;
