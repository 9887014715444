import React from "react";
import "./stars.scss";
import Typed from "react-typed";
import { Container, Typography, Button, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import bgimage from "../img/bgimage.jpg";

class Intro extends React.Component {
  render() {
    return (
      <section id="home" className="hero" style={{ minHeight: '100vh' }}>
        <div className="hero-background" style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${bgimage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: -1
        }}></div>
        
        <Container maxWidth="lg" sx={{ 
          height: '100vh', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          position: 'relative',
          zIndex: 1
        }}>
          <Box sx={{ maxWidth: '800px' }}>
            <Typography 
              variant="h1" 
              sx={{ 
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                fontWeight: 700,
                color: '#fff',
                mb: 3,
                maxWidth: '800px',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)'
              }}
            >
              Building with Excellence, Strength, and Trust
            </Typography>
            
            <Typography 
              variant="h2" 
              sx={{ 
                fontSize: { xs: '1.5rem', md: '2rem' },
                fontWeight: 400,
                color: '#fff',
                mb: 4,
                maxWidth: '700px',
                lineHeight: 1.4,
                textShadow: '0 1px 2px rgba(0,0,0,0.3)'
              }}
            >
              A leading construction and architectural company with years of experience
            </Typography>
            
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h3" 
                sx={{ 
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                  fontWeight: 400,
                  color: '#fff',
                  mb: 1,
                  textShadow: '0 1px 2px rgba(0,0,0,0.3)'
                }}
              >
                Specializing in:
              </Typography>
              <Typography 
                variant="h3" 
                sx={{ 
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                  fontWeight: 400,
                  color: '#fff',
                  textShadow: '0 1px 2px rgba(0,0,0,0.3)'
                }}
              >
                <Typed
                  strings={[
                    "Precast Wall Panel Installation",
                    "Tiling & Flooring",
                    "Metal Works & Fabrication",
                    "Plastering & Finishing",
                    "Skimcoat & Painting"
                  ]}
                  typeSpeed={40}
                  backSpeed={50}
                  loop
                />
              </Typography>
            </Box>
              <Box sx={{ display: 'flex', gap: 3, flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                <Button
                  variant="contained"
                  href="#work"
                  className="js-scroll"
                  sx={{
                      bgcolor: '#fff',
                      color: '#333',
                      px: 4,
                      py: 1.8,
                      fontSize: '1rem',
                      fontWeight: 600,
                      borderRadius: '50px',
                      textTransform: 'none',
                      boxShadow: '0 4px 15px rgba(0,0,0,0.15)',
                      mb: { xs: 2, sm: 0 },
                      '&:hover': {
                          bgcolor: '#fff',
                          transform: 'translateY(-3px)',
                          boxShadow: '0 8px 20px rgba(0,0,0,0.25)',
                      }
                  }}
                >
                  View Our Portfolio
                </Button>

                <Button
                  variant="contained" 
                  href="#about"
                  className="js-scroll"
                  endIcon={<ArrowForwardIcon />}
                  sx={{ 
                    backgroundColor: 'rgba(0, 123, 255, 0.9)',
                    color: 'white',
                    py: 1.8,
                    px: 4,
                    fontSize: '1rem',
                    fontWeight: 600,
                    borderRadius: '50px',
                    textTransform: 'none',
                    boxShadow: '0 4px 15px rgba(0,123,255,0.3)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 123, 255, 1)',
                      transform: 'translateY(-3px)',
                      boxShadow: '0 8px 20px rgba(0,123,255,0.4)'
                    }
                  }}
                >
                  Learn More
                </Button>
              </Box>
          </Box>
        </Container>
      </section>
    );
  }
}

export default Intro;
