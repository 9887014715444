import React from "react";
import { Container, Grid, Typography, Box, Card, CardContent, CardMedia, Button, Chip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//import stock
import stock from "../img/image1.jpg";
import stock1 from "../img/image2.jpg";
import stock2 from "../img/image3.jpg";
import stock3 from "../img/image4.jpg";
import stock4 from "../img/image5.jpg";
import stock5 from "../img/image6.jpg";
import nanhua1 from "../img/nanhua/image1.jpeg";
import nanhuatn from "../img/nanhua/thumbnail.jpeg";
import nanhua2 from "../img/nanhua/image2.jpg";
import nanhua3 from "../img/nanhua/image3.jpeg";
import nanhua4 from "../img/nanhua/image4.jpeg";
import nanhua5 from "../img/nanhua/image5.jpg";
import yusof1 from "../img/yusof/image1.jpeg";
import yusoftn from "../img/yusof/thumbnail.jpeg";
import yusof2 from "../img/yusof/image2.jpeg";
import yusof3 from "../img/yusof/image3.jpeg";
import yusof4 from "../img/yusof/image4.jpeg";
import yusof5 from "../img/yusof/image5.jpeg";
import mayflower1 from "../img/mayflower/image1.jpg";
import mayflowertn from "../img/mayflower/thumbnail.jpeg";
import mayflower2 from "../img/mayflower/image2.jpg";
import mayflower3 from "../img/mayflower/image3.jpg";
import mayflower4 from "../img/mayflower/image4.jpg";
import mayflower5 from "../img/mayflower/image5.jpg";

class Portfolio extends React.Component {
  constructor() {
    super();
    this.state = {
      projects: [
        {
          id: "nanhua",
          title: "Nan Hua Primary School",
          location: "Clementi, Singapore",
          year: "2022",
          category: "Educational",
          description: "Renovation and upgrading works for Nan Hua Primary School, including tiling, plastering, and panel installation.",
          thumbnail: nanhuatn,
          images: [nanhua1, nanhua2, nanhua3, nanhua4, nanhua5],
          services: ["Panel Installation", "Tiling", "Plastering"]
        },
        {
          id: "yusof",
          title: "Yusof Ishak Secondary School",
          location: "Bukit Batok, Singapore",
          year: "2021",
          category: "Educational",
          description: "Comprehensive renovation project for Yusof Ishak Secondary School, focusing on modern infrastructure upgrades.",
          thumbnail: yusoftn,
          images: [yusof1, yusof2, yusof3, yusof4, yusof5],
          services: ["Structural Works", "Tiling", "Plastering"]
        },
        {
          id: "mayflower",
          title: "Mayflower Primary School",
          location: "Ang Mo Kio, Singapore",
          year: "2023",
          category: "Educational",
          description: "Upgrading works for Mayflower Primary School, including classroom renovations and facility improvements.",
          thumbnail: mayflowertn,
          images: [mayflower1, mayflower2, mayflower3, mayflower4, mayflower5],
          services: ["Panel Installation", "Skimcoat", "Skirting"]
        }
      ],
      filters: ["All", "Educational", "Commercial", "Residential"],
      activeFilter: "All"
    };
  }

  handleFilterClick = (filter) => {
    this.setState({ activeFilter: filter });
  }

  render() {
    const { projects, filters, activeFilter } = this.state;
    const filteredProjects = activeFilter === "All" 
      ? projects 
      : projects.filter(project => project.category === activeFilter);

    return (
      <section id="work" className="sect-pt4 route">
        <Container maxWidth="xl">
          {/* Section Header */}
          <Box className="section-header" sx={{ mb: 6 }}>
            <Typography variant="h2" component="h2">
              Our Projects
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, maxWidth: '700px', mx: 'auto', color: '#666' }}>
              Explore our portfolio of completed projects showcasing our expertise and commitment to quality
            </Typography>
          </Box>

          {/* Project Filters */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5, flexWrap: 'wrap', gap: 1 }}>
            {filters.map(filter => (
              <Button 
                key={filter}
                variant={activeFilter === filter ? "contained" : "outlined"}
                onClick={() => this.handleFilterClick(filter)}
                sx={{ 
                  mx: 1, 
                  mb: 1,
                  px: 3,
                  borderRadius: '50px',
                  backgroundColor: activeFilter === filter ? 'var(--primary-color)' : 'transparent',
                  borderColor: activeFilter === filter ? 'var(--primary-color)' : '#ccc',
                  color: activeFilter === filter ? '#fff' : '#666',
                  '&:hover': {
                    backgroundColor: activeFilter === filter ? 'var(--primary-color)' : 'rgba(0,0,0,0.04)',
                    borderColor: 'var(--primary-color)'
                  }
                }}
              >
                {filter}
              </Button>
            ))}
          </Box>

          {/* Projects Grid */}
          <Grid container spacing={4}>
            {filteredProjects.map(project => (
              <Grid item xs={12} md={4} key={project.id}>
                <Card className="modern-card" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={project.thumbnail}
                      alt={project.title}
                      className="modern-image"
                    />
                    <Box className="overlay" sx={{ 
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0,123,255,0.7)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      '&:hover': {
                        opacity: 1
                      }
                    }}>
                      <Button 
                        variant="contained" 
                        href={project.images[0]} 
                        data-lightbox={`gallery-${project.id}`}
                        sx={{ 
                          backgroundColor: '#fff',
                          color: '#333',
                          '&:hover': {
                            backgroundColor: '#f8f9fa'
                          }
                        }}
                      >
                        View Project
                      </Button>
                      {project.images.slice(1).map((image, index) => (
                        <a
                          key={index}
                          href={image}
                          data-lightbox={`gallery-${project.id}`}
                          style={{ display: "none" }}
                          aria-hidden="true"
                        >
                          Hidden lightbox link
                        </a>
                      ))}
                    </Box>
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h5" component="h3" sx={{ fontWeight: 600, mb: 2 }}>
                      {project.title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <LocationOnIcon sx={{ color: '#666', fontSize: '1rem', mr: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        {project.location}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <CalendarTodayIcon sx={{ color: '#666', fontSize: '1rem', mr: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        Completed in {project.year}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mb: 3 }}>
                      {project.description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {project.services.map((service, index) => (
                        <Chip 
                          key={index} 
                          label={service} 
                          size="small"
                          sx={{ 
                            backgroundColor: 'rgba(0,123,255,0.1)',
                            color: 'var(--primary-color)',
                            fontWeight: 500
                          }} 
                        />
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* View More Button
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Button 
              variant="outlined" 
              endIcon={<ArrowForwardIcon />}
              sx={{ 
                borderColor: 'var(--primary-color)',
                color: 'var(--primary-color)',
                px: 4,
                py: 1,
                '&:hover': {
                  borderColor: 'var(--primary-color)',
                  backgroundColor: 'rgba(0,123,255,0.05)'
                }
              }}
            >
              View More Projects
            </Button>
          </Box> */}
        </Container>
      </section>
    );
  }
}

export default Portfolio;
