import React from "react";
import { Container, Grid, Typography, Box, Card, CardContent, CardMedia, Divider } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import VerifiedIcon from '@mui/icons-material/Verified';

import drain from "../img/services/drain_cover.jpg";
import fence from "../img/services/fence.jpg";
import metal from "../img/services/metal_staircase.jpg";
import railing from "../img/services/railings.jpg";
import bgimage from "../img/bgimage.jpg";
import yusof1 from "../img/yusof/image1.jpeg";


class About extends React.Component {
  constructor() {
    super();
    this.state = {
      achievements: [
        { id: "years", number: "8+", label: "Years Experience" },
        { id: "projects", number: "50+", label: "Projects Completed" },
        { id: "clients", number: "30+", label: "Satisfied Clients" },
        { id: "team", number: "25+", label: "Team Members" }
      ],
      services: [
        { 
          id: "service1", 
          title: "Panel Installation", 
          description: "Professional installation of precast wall panels for residential and commercial projects.",
          image: drain,
          icon: <ConstructionIcon fontSize="large" />
        },
        { 
          id: "service2", 
          title: "Tiling & Flooring", 
          description: "Expert tiling services with precision and attention to detail for all types of surfaces.",
          image: fence,
          icon: <EngineeringIcon fontSize="large" />
        },
        { 
          id: "service3", 
          title: "Metal Works", 
          description: "Custom metal fabrication and installation for staircases, railings and structural elements.",
          image: metal,
          icon: <ConstructionIcon fontSize="large" />
        },
        { 
          id: "service4", 
          title: "Plastering & Finishing", 
          description: "High-quality plastering and finishing services for a perfect final appearance.",
          image: railing,
          icon: <BusinessIcon fontSize="large" />
        }
      ],
      about_text: "Founded in 2015, Chin Hoong Construction & Engineering Pte Ltd is an established Singapore-based engineering and construction services company. We specialise in wet works such as tiling, screeding, plastering and installation of precast wall panels in the construction trade. With our team of experienced professionals, we strive to deliver high-quality construction solutions that meet our clients' requirements and exceed their expectations."
    };
  }

  render() {
    return (
      <section id="about" className="sect-pt4 route" style={{ 
        backgroundColor: '#f8f9fa',
        position: 'relative',
        overflow: 'hidden'
      }}>
        {/* Background Image with Overlay */}
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `linear-gradient(rgba(248, 249, 250, 0.8), rgba(248, 249, 250, 0.85)), url(${bgimage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0
        }} />
        
        <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1 }}>
          {/* Section Header */}
          <Box className="section-header" sx={{ mb: 6 }}>
            <Typography variant="h2" component="h2">
              About Us
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, maxWidth: '700px', mx: 'auto', color: '#666' }}>
              An established construction and engineering company with a reputation for quality and reliability
            </Typography>
          </Box>

          {/* About Text */}
          <Grid container spacing={4} sx={{ mb: 8 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h3" sx={{ mb: 3, fontWeight: 600 }}>
                Our Story
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, lineHeight: 1.8 }}>
                {this.state.about_text}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <VerifiedIcon sx={{ color: 'var(--primary-color)', mr: 2 }} />
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  BCA Registered Contractor
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <VerifiedIcon sx={{ color: 'var(--primary-color)', mr: 2 }} />
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  ISO 9001:2015 Certified
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <VerifiedIcon sx={{ color: 'var(--primary-color)', mr: 2 }} />
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  bizSAFE Level 3 Certified
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                height: '100%', 
                backgroundImage: `url(${yusof1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
              }} />
            </Grid>
          </Grid>

          {/* Achievements */}
          <Box sx={{ 
            py: 5, 
            px: { xs: 2, md: 5 }, 
            mb: 8, 
            backgroundColor: '#fff', 
            borderRadius: '8px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
          }}>
            <Grid container spacing={3}>
              {this.state.achievements.map(achievement => (
                <Grid item xs={6} md={3} key={achievement.id}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography 
                      variant="h3" 
                      sx={{ 
                        fontSize: { xs: '2.5rem', md: '3rem' }, 
                        fontWeight: 700,
                        color: 'var(--primary-color)'
                      }}
                    >
                      {achievement.number}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {achievement.label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Core Services */}
          <Typography variant="h4" component="h3" sx={{ mb: 4, fontWeight: 600, textAlign: 'center' }}>
            Our Core Services
          </Typography>
          <Grid container spacing={4}>
            {this.state.services.map(service => (
              <Grid item xs={12} sm={6} md={3} key={service.id}>
                <Card className="modern-card" sx={{ height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="160"
                    image={service.image}
                    alt={service.title}
                    className="modern-image"
                  />
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ 
                        mr: 2, 
                        color: 'var(--primary-color)',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        {service.icon}
                      </Box>
                      <Typography variant="h6" component="h3" sx={{ fontWeight: 600 }}>
                        {service.title}
                      </Typography>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="body2" color="text.secondary">
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>
    );
  }
}

export default About;
